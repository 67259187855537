$theme: dark;

@import "external";
@import "bootstrap_dark";
@import "all";

.alert-info {
  background: theme-color-level(info, $alert-bg-level);
  box-shadow: inset 0 0 5px theme-color-level(info, $alert-border-level);
}
.alert-success {
  background: theme-color-level(success, $alert-bg-level);
  box-shadow: inset 0 0 5px theme-color-level(success, $alert-border-level);
}
.alert-danger {
  background: theme-color-level(danger, $alert-bg-level);
  box-shadow: inset 0 0 5px theme-color-level(danger, $alert-border-level);
}

.btn-outline-danger {
  // make it a regular .btn-danger
  @include button-variant($danger, $danger);
}

.card {
  box-shadow: 0 0 10px rgba(#000, 0.2);
}

.nav-tabs .nav-link:not(.active):hover {
  background-color: $alternatingTableRowBg;
}
